import * as React from "react"
import { styled } from "@mui/material/styles";

import Layout from '../components/Layout';
import { Title, Text as CustomText } from '../mui-elements/shared';
import { SEO } from '../components/SEO';

const Text = styled(CustomText)({
  textAlign: 'justify',
}) as typeof CustomText;

import {
  Wrapper,
  Section
} from '../style/pages/footer-pages';

export default function APITermsOfUse() {
  return (
    <Layout>
      <Wrapper>
        <Section>
          <Title component="h2" variant="h2">
            Voltaware APIs Terms of Service
          </Title>
          <Text>
            Thank you for using Voltaware's APIs, other developer services, and 
            associated software (collectively, "APIs"). By accessing or using 
            our APIs, you are agreeing to the terms below. If there is a 
            conflict between these terms and additional terms applicable to a 
            given API, the additional terms will control for that conflict. 
            Collectively, we refer to the terms below, any additional terms, 
            terms within the accompanying API documentation, and any applicable 
            policies and guidelines as the "Terms." You agree to comply with 
            the Terms and that the Terms control your relationship with us. So 
            please read all the Terms carefully. If you use the APIs as an 
            interface to, or in conjunction with other Voltaware products or 
            services, then the terms for those other products or services also 
            apply.
          </Text>

          <Title component="h2" variant="h2">
            Section 1: Account and Registration
          </Title>
          <Title component="h3" variant="h3">
            a. Accepting the Terms
          </Title>
          <Text>
            You may not use the APIs and may not accept the Terms if (a) you 
            are not of legal age to form a binding contract with Voltaware, or 
            (b) you are a person barred from using or receiving the APIs under 
            the applicable laws of the United Kingdom or other countries 
            including the country in which you are resident or from which you 
            use the APIs.
          </Text>

          <Title component="h3" variant="h3">
            b. Entity Level Acceptance
          </Title>
          <Text>
            If you are using the APIs on behalf of an entity, you represent and 
            warrant that you have authority to bind that entity to the Terms and
             by accepting the Terms, you are doing so on behalf of that entity 
             (and all references to "you" in the Terms refer to that entity).
          </Text>

          <Title component="h3" variant="h3">
            c. Registration
          </Title>
          <Text>
            In order to access certain APIs you may be required to provide 
            certain information (such as identification or contact details) as 
            part of the registration process for the APIs, or as part of your 
            continued use of the APIs. Any registration information you give to 
            Voltaware will always be accurate and up to date and you'll inform 
            us promptly of any updates.
          </Text>

          <Title component="h3" variant="h3">
            d. Subsidiaries and Affiliates
          </Title>
          <Text>
            Voltaware has subsidiaries and affiliated legal entities around the 
            world. These companies may provide the APIs to you on behalf of 
            Voltaware and the Terms will also govern your relationship with 
            these companies.
          </Text>

          <Title component="h2" variant="h2">
            Section 2: Using Our APIs
          </Title>

          <Title component="h3" variant="h3">
            a. Your End Users
          </Title>
          <Text>
            You will require your end users to comply with (and not knowingly 
            enable them to violate) applicable law, regulation, and the Terms.
          </Text>

          <Title component="h3" variant="h3">
            b. Compliance with Law, Third Party Rights, and Other Voltaware 
            Terms of Service
          </Title>
          <Text>
            You will comply with all applicable law, regulation, and third 
            party rights (including without limitation laws regarding the 
            import or export of data or software, privacy, and local laws). You 
            will not use the APIs to encourage or promote illegal activity or 
            violation of third party rights. You will not violate any other 
            terms of service with Voltaware (or its affiliates).
          </Text>

          <Title component="h3" variant="h3">
            c. Permitted Access
          </Title>
          <Text>
            You will only access (or attempt to access) an API by the means 
            described in the documentation of that API. If Voltaware assigns 
            you developer credentials (e.g. client IDs), you must use them with 
            the applicable APIs. You will not misrepresent or mask either your 
            identity or your API Client's identity when using the APIs or 
            developer accounts.
          </Text>

          <Title component="h3" variant="h3">
            d. API Limitations
          </Title>
          <Text>
            Voltaware sets and enforces limits on your use of the APIs (e.g. 
            limiting the number of API requests that you may make or the number 
            of users you may serve), in our sole discretion. You agree to, and 
            will not attempt to circumvent, such limitations documented with 
            each API. If you would like to use any API beyond these limits, 
            you must obtain Voltaware's express consent (and Voltaware may 
            decline such request or condition acceptance on your agreement to 
            additional terms and/or charges for that use). To seek such 
            approval, contact the relevant Voltaware API team for information 
            (e.g. by using the Voltaware developers console).
          </Text>

          <Title component="h3" variant="h3">
            e. Open Source Software
          </Title>
          <Text>
            Some of the software required by or included in our APIs may be 
            offered under an open source license. Open source software 
            licenses constitute separate written agreements. For certain APIs, 
            open source software is listed in the documentation. To the limited 
            extent the open source software license expressly supersedes the 
            Terms, the open source license instead sets forth your agreement 
            with Voltaware for the applicable open source software.
          </Text>

          <Title component="h3" variant="h3">
            f. Communication with Voltaware
          </Title>
          <Text>
            We may send you certain communications in connection with your use 
            of the APIs. Please review the applicable API documentation for 
            information about opting out of certain types of communication.
          </Text>

          <Title component="h3" variant="h3">
            g. Feedback
          </Title>
          <Text>
            If you provide feedback or suggestions about our APIs, then we (and 
            those we allow) may use such information without obligation to you.
          </Text>

          <Title component="h3" variant="h3">
            h. Non-Exclusivity
          </Title>
          <Text>
            The Terms are non-exclusive. You acknowledge that Voltaware may 
            develop products or services that may compete with the API Clients 
            or any other products or services.
          </Text>

          <Title component="h2" variant="h2">
            Section 3: Your API Clients
          </Title>

          <Title component="h3" variant="h3">
            a. API Clients and Monitoring
          </Title>
          <Text>
            The APIs are designed to help you enhance your websites and 
            applications ("API Client(s)"). You agree that Voltaware may 
            monitor use of the APIs to ensure quality, improve Voltaware 
            products and services, and verify your compliance with the terms. 
            This monitoring may include Voltaware accessing and using your API 
            Client, for example to identify security issues that could affect 
            Voltaware or its users. You will not interfere with this monitoring.
             Voltaware may use any technical means to overcome such 
             interference. Voltaware may suspend access to the APIs by you or 
             your API Client without notice if we reasonably believe that you 
             are in violation of the Terms.
          </Text>

          <Title component="h3" variant="h3">
            b. Security
          </Title>
          <Text>
            You will use commercially reasonable efforts to protect user 
            information collected by your API Client, including personally 
            identifiable information ("PII"), from unauthorized access or use 
            and will promptly report to your users any unauthorized access or 
            use of such information to the extent required by applicable law.
          </Text>

          <Title component="h3" variant="h3">
            c. Ownership
          </Title>
          <Text>
            Voltaware does not acquire ownership in your API Clients, and by 
            using our APIs, you do not acquire ownership of any rights in our 
            APIs or the content that is accessed through our APIs.
          </Text>

          <Title component="h3" variant="h3">
            d. User Privacy and API Clients
          </Title>
          <Text>
            You will comply with all applicable privacy laws and regulations 
            including those applying to PII. You will provide and adhere to a 
            privacy policy for your API Client that clearly and accurately 
            describes to users of your API Client what user information you 
            collect and how you use and share such information (including for 
            advertising) with Voltaware and third parties.
          </Text>

          <Title component="h2" variant="h2">
            Section 4: Prohibitions and Confidentiality
          </Title>

          <Title component="h3" variant="h3">
            a. API Prohibitions
          </Title>
          <Text>
            When using the APIs, you may not (or allow those acting on your 
            behalf to):
          </Text>
          <ol>
            <li>
              <Text>Sublicense an API for use by a third party. Consequently, 
                you will not create an API Client that functions substantially 
                the same as the APIs and offer it for use by third parties.
              </Text>
            </li>
            <li>
              <Text>Perform an action with the intent of introducing to 
                Voltaware products and services any viruses, worms, 
                defects, Trojan horses, malware, or any items of a destructive 
                nature.
              </Text>
            </li>
            <li>
              <Text>
                Defame, abuse, harass, stalk, or threaten others.
              </Text>
            </li>
            <li>
              <Text>Interfere with or disrupt the APIs or the servers or 
                networks providing the APIs.
              </Text>
            </li>
            <li>
              <Text>Promote or facilitate unlawful online gambling or 
                disruptive commercial messages or advertisements.
              </Text>
            </li>
            <li>
              <Text>
                Reverse engineer or attempt to extract the source code from any 
                API or any related software, except to the extent that this 
                restriction is expressly prohibited by applicable law.
              </Text>
            </li>
            <li>
              <Text>
                Use the APIs for any activities where the use or failure of the 
                APIs could lead to death, personal injury, or environmental 
                damage (such as the operation of nuclear facilities, air 
                traffic control, or life support systems).
              </Text>
            </li>
            <li>
              <Text>
                Remove, obscure, or alter any Voltaware terms of service or any 
                links to or notices of those terms.
              </Text>
            </li>
          </ol>

          <Title component="h3" variant="h3">
            b. Confidential Matters
          </Title>
          <ol>
            <li>
              <Text>
                Developer credentials (such as passwords, keys, and client IDs) 
                are intended to be used by you and identify your API Client. 
                You will keep your credentials confidential and make reasonable 
                efforts to prevent and discourage other API Clients from using 
                your credentials. Developer credentials may not be embedded in 
                open source projects.
              </Text>
            </li>
            <li>
              <Text>
                Our communications to you and our APIs may contain Voltaware 
                confidential information. Voltaware confidential information 
                includes any materials, communications, and information that 
                are marked confidential or that would normally be considered 
                confidential under the circumstances. If you receive any such 
                information, then you will not disclose it to any third party 
                without Voltaware's prior written consent. Voltaware 
                confidential information does not include information that you 
                independently developed, that was rightfully given to you by a 
                third party without confidentiality obligation, or that becomes 
                public through no fault of your own. You may disclose Voltaware 
                confidential information when compelled to do so by law if you 
                provide us reasonable prior notice, unless a court orders that 
                we not receive notice.
              </Text>
            </li>
          </ol>

          <Title component="h3" variant="h3">
            c. Data Portability
          </Title>
          <Text>
            Voltaware supports data portability. For as long as you use or store
             any user data that you obtained through the APIs, you agree to 
             enable your users to export their equivalent data to other services
              or applications of their choice in a way that's substantially 
              as fast and easy as exporting such data from Voltaware products 
              and services, subject to applicable laws, and you agree that you 
              will not make that data available to third parties who do not 
              also abide by this obligation.
          </Text>

          <Title component="h3" variant="h3">
            d. Prohibitions on Content
          </Title>
          <Text>
            Unless expressly permitted by the content owner or by applicable 
            law, you will not, and will not permit your end users or others 
            acting on your behalf to, do the following with content returned 
            from the APIs:
          </Text>
          <ol>
            <li>
              <Text>
                Scrape, build databases, or otherwise create permanent copies 
                of such content, or keep cached copies longer than permitted by 
                the cache header;
              </Text>
            </li>
            <li>
              <Text>
                Copy, translate, modify, create a derivative work of, sell, 
                lease, lend, convey, distribute, publicly display, or sublicense
                 to any third party;
              </Text>
            </li>
            <li>
              <Text>
                Misrepresent the source or ownership; or
              </Text>
            </li>
            <li>
              <Text>
                Remove, obscure, or alter any copyright, trademark, or other 
                proprietary rights notices; or falsify or delete any author 
                attributions, legal notices, or other labels of the origin or 
                source of material.
              </Text>
            </li>
          </ol>

          <Title component="h3" variant="h3">
            e. Database and Data Protection
          </Title>
          <Text>
            All personal data pertaining to Developers and directed third 
            parties shall, upon application, belong to us and all rights and 
            responsibilities pertaining to that data shall reside with us and 
            be subject to the conditions set out in the Voltaware Terms and 
            Conditions.
          </Text>

          <Title component="h2" variant="h2">
            Section 5: Brand Features; Attribution
          </Title>

          <Title component="h3" variant="h3">
            a. Brand Features
          </Title>
          <Text>
            "Brand Features" is defined as the trade names, trademarks, service 
            marks, logos, domain names, and other distinctive brand features of 
            each party. Except where expressly stated, the Terms do not grant 
            either party any right, title, or interest in or to the other 
            party's Brand Features. All use by you of Voltaware's Brand 
            Features (including any goodwill associated therewith) will inure 
            to the benefit of Voltaware.
          </Text>

          <Title component="h3" variant="h3">
            b. Attribution
          </Title>
          <Text>
            You agree to display any attribution(s) required by Voltaware as 
            described in the documentation for the API. Voltaware hereby grants 
            to you a non transferable, non sublicensable, nonexclusive license 
            while the Terms are in effect to display Voltaware's Brand Features 
            for the purpose of promoting or advertising that you use the APIs. 
            You must only use the Voltaware Brand Features in accordance with 
            the Terms and for the purpose of fulfilling your obligations under 
            this Section. In using Voltaware's Brand Features, you must follow 
            the Voltaware Brand Features Use Guidelines. You understand and 
            agree that Voltaware has the sole discretion to determine whether 
            your attribution(s) and use of Voltaware's Brand Features are in 
            accordance with the above requirements and guidelines.
          </Text>

          <Title component="h3" variant="h3">
            c. Publicity
          </Title>
          <Text>
            You will not make any statement regarding your use of an API which 
            suggests partnership with, sponsorship by, or endorsement by 
            Voltaware without Voltaware's prior written approval.
          </Text>

          <Title component="h3" variant="h3">
            d. Promotional and Marketing Use
          </Title>
          <Text>
            In the course of promoting, marketing, or demonstrating the APIs 
            you are using and the associated Voltaware products, Voltaware may 
            produce and distribute incidental depictions, including 
            screenshots, video, or other content from your API Client, and may 
            use your company or product name. You grant us all necessary rights 
            for the above purposes.
          </Text>

          <Title component="h2" variant="h2">
            Section 6: Termination
          </Title>

          <Title component="h3" variant="h3">
            a. Termination
          </Title>
          <Text>
            You may stop using our APIs at any time with or without notice. 
            Further, if you want to terminate the Terms, you must provide 
            Voltaware with prior written notice and upon termination, cease 
            your use of the applicable APIs. Voltaware reserves the right to 
            terminate the Terms with you or discontinue the APIs or any portion 
            or feature or your access thereto for any reason and at any time 
            without liability or other obligation to you.
          </Text>

          <Title component="h3" variant="h3">
            b. Your Obligations Post-Termination
          </Title>
          <Text>
            Upon any termination of the Terms or discontinuation of your access 
            to an API, you will immediately stop using the API, cease all use 
            of the Voltaware Brand Features, and delete any cached or stored 
            content that was permitted by the cache header under Section 5. 
            Voltaware may independently communicate with any account owner 
            whose account(s) are associated with your API Client and developer 
            credentials to provide notice of the termination of your right to 
            use an API.
          </Text>

          <Title component="h3" variant="h3">
            c. Surviving Provisions
          </Title>
          <Text>
            When the Terms come to an end, those terms that by their nature are 
            intended to continue indefinitely will continue to apply, including 
            but not limited to: Sections 4b, 5, 8, 9, and 10.
          </Text>

          <Title component="h2" variant="h2">
            Section 7: Liability for our APIs
          </Title>

          <Title component="h3" variant="h3">
            a. Warranties
          </Title>
          <Text>
            Except as expressly set out in the terms, neither Voltaware nor its 
            suppliers or distributors make any specific promises about the APIs. 
            for example, we don't make any commitments about the content 
            accessed through the APIs, the specific functions of the APIs, or 
            their reliability, availability, or ability to meet your needs. we 
            provide the APIs "as is".
          </Text>
          <Text>
            Some jurisdictions provide for certain warranties, like the implied 
            warranty of merchantability, fitness for a particular purpose, and 
            non-infringement. except as expressly provided for in the terms, to 
            the extent permitted by law, we exclude all warranties, guarantees, 
            conditions, representations, and undertakings.
          </Text>

          <Title component="h3" variant="h3">
            b. Limitation Of Liability
          </Title>
          <Text>
            When permitted by law, Voltaware, and Voltaware's suppliers and 
            distributors, will not be responsible for lost profits, revenues, 
            or data; financial losses; or indirect, special, consequential, 
            exemplary, or punitive damages.
          </Text>
          <Text>
            To the extent permitted by law, the total liability of Voltaware, 
            and its suppliers and distributors, for any claim under the terms, 
            including for any implied warranties, is limited to the amount you 
            paid us to use the applicable APIs (or, if we choose, to supplying 
            you the APIs again) during the six months prior to the event giving 
            rise to the liability.
          </Text>
          <Text>
            In all cases, Voltaware, and its suppliers and distributors, will 
            not be liable for any expense, loss, or damage that is not 
            reasonably foreseeable.
          </Text>

          <Title component="h3" variant="h3">
            c. Indemnification
          </Title>
          <Text>
            Unless prohibited by applicable law, if you are a business, you 
            will defend and indemnify Voltaware, and its affiliates, 
            directors, officers, employees, and users, against all liabilities, 
            damages, losses, costs, fees (including legal fees), and expenses 
            relating to any allegation or third-party legal proceeding to the 
            extent arising from:
          </Text>
          <ol>
            <li>
              <Text>your misuse or your end user's misuse of the APIs;</Text>
            </li>
            <li>
              <Text>your violation or your end user's violation of the Terms; or</Text>
            </li>
            <li>
              <Text>any content or data routed into or used with the APIs by 
                you, those acting on your behalf, or your end users.
              </Text>
            </li>
          </ol>

          <Title component="h2" variant="h2">
            Section 8: General Provisions
          </Title>

          <Title component="h3" variant="h3">
            a. Modification
          </Title>
          <Text>
            We may modify the Terms or any portion to, for example, reflect 
            changes to the law or changes to our APIs. You should look at the 
            Terms regularly. We'll post notice of modifications to the Terms 
            within the documentation of each applicable API, to this website, 
            and/or in the Voltaware developers console. Changes will not apply 
            retroactively and will become effective no sooner than 30 days 
            after they are posted. But changes addressing new functions for an 
            API or changes made for legal reasons will be effective 
            immediately. If you do not agree to the modified Terms for an API, 
            you should discontinue your use of that API. Your continued use of 
            the API constitutes your acceptance of the modified Terms.
          </Text>

          <Title component="h3" variant="h3">
            b. General Legal Terms
          </Title>
          <Text>
            We each agree to contract in the English language. If we provide a 
            translation of the Terms, we do so for your convenience only and 
            the English Terms will solely govern our relationship. The Terms do 
            not create any third party beneficiary rights or any agency, 
            partnership, or joint venture. Nothing in the Terms will limit 
            either party's ability to seek injunctive relief. We are not liable 
            for failure or delay in performance to the extent caused by 
            circumstances beyond our reasonable control. If you do not comply 
            with the Terms, and Voltaware does not take action right away, this 
            does not mean that Voltaware is giving up any rights that it may 
            have (such as taking action in the future). If it turns out that a 
            particular term is not enforceable, this will not affect any other 
            terms. The Terms are the entire agreement between you and Voltaware 
            relating to its subject and supersede any prior or contemporaneous 
            agreements on that subject.
          </Text>

          <Title component="h2" variant="h2">
            Section 9: Choice of law
          </Title>
          <Text>
            These Developer Terms shall be governed by English law and the 
            English courts shall have exclusive jurisdiction to adjudicate upon 
            any claim or dispute which may arise out of or in connection them 
            or in respect of the Voltaware Terms and Conditions.
          </Text>
        </Section>
      </Wrapper>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
