import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 3rem;
  margin: 0 auto;

  p {
    @media (max-width: 900px) {
      font-size: 1.2rem;
    }
  }
`;

export const Section = styled.section`
  margin-bottom: 2rem;

  h2 {
    text-align: left;
    border-bottom: 2px solid rgba(75, 140, 42, 0.5);
    padding: 10px;
  }

  p + h2 {
    padding-top: 20px;
  }

  
  h3 {
    text-align: left;
    color: ${({ theme }) => theme.colors.black_lighter};
    font-size: 1.5rem;
  }

  p + h3 {
    padding-top: 10px;
  }

  ol li::marker {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba(80,80,80,0.6);
    font-family: Quicksand, sans-serif;
  }
`;